import { RoomCardProps } from '@app/components/RoomCard/RoomCard.interfaces';
import { HotelImagesInterface, HotelRoomPlanInterface } from '@app/models/search';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { parseRateKey } from './utils';
import { getHotelRates, getHotelRatesByDate } from '@app/services/booking';
import { useUser } from '@app/context/user';
import { useSearch } from '@app/context/search';
import { HotelImageCategories, PaymentTypes } from '@app/consts/enums';
import { groupBy } from 'lodash';
import { useBooking } from '@app/context/booking';
import { getFeedbackByHotelId } from '@app/services/feedback';
import { addDays, format } from 'date-fns';
import { toast } from 'react-toastify';
import { FeedbackResponseInterface } from '@app/models/feedback';
import { useForm } from 'react-hook-form';

export const useResultDetails = () => {
  const navigate = useNavigate();
  const [showAllPhotos, setShowAllPhotos] = useState(false);
  const [selectPaymentType, setPaymentType] = useState<PaymentTypes | undefined>();
  const [openRateModal] = useState<Partial<HotelRoomPlanInterface & RoomCardProps> | undefined>();
  const [showMoreReviews, setShowMoreReviews] = useState(false);
  const [hotelImages, setHotelImages] = useState<HotelImagesInterface | undefined>();
  const [hotelFeedback, setHotelFeedback] = useState<FeedbackResponseInterface>();
  const [hotelRates, setHotelRates] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const { searchParams: userSearchParams } = useSearch();
  const { bookingsDispatch } = useBooking();
  const { getPriceFormat } = useUser();
  const feedbackForm = useForm({ defaultValues: { orderBy: { label: 'Most Recent', value: 'NEWEST' } } });
  const hotelId = searchParams?.get('hotelId');
  const parsedRateKey = parseRateKey(searchParams?.get('id') ?? '');
  const { data: hotelDetails, isFetching } = useQuery({
    queryKey: ['hotel-details'],
    queryFn: () => {
      if (hotelId) {
        return getHotelRatesByDate(searchParams.get('hotelId') as string, 'GLOBAL', true, {
          adults: 1,
          children: 0,
          inDate: format(addDays(new Date(), 1).toISOString(), 'yyyy-MM-dd'),
          outDate: format(addDays(new Date(), 2).toISOString(), 'yyyy-MM-dd'),
        })
          .then((res) => res?.data)
          .catch((err) => {
            toast.error(
              err?.response?.data?.error ??
                err?.data?.error ??
                'Error while trying to fetch hotel information and rates.',
            );
            toast.clearWaitingQueue();
          });
      }
      return getHotelRates({ sourceRateCode: parseRateKey(searchParams?.get('id') ?? '') })
        .then((res) => res.data)
        .catch((err) => {
          toast.error(
            err?.response?.data?.error ??
              err?.data?.error ??
              'Error while trying to fetch hotel information and rates.',
          );
          toast.clearWaitingQueue();
        });
    },
    enabled: Boolean(parsedRateKey || hotelId),
    retry: false,
    refetchOnWindowFocus: false,
  });

  const {
    data: hotelNewRates,
    mutate,
    isPending,
  } = useMutation({
    mutationKey: ['hotel-details-update'],
    mutationFn: () =>
      getHotelRatesByDate(hotelDetails?.rateResults?.sourceHotelCode, undefined, true, userSearchParams)
        .then((res) => res?.data)
        .catch((err) => {
          toast.error(err?.response?.data?.error ?? err?.data?.error ?? 'Error while fetching new rates information.');
          toast.clearWaitingQueue();
        }),

    retry: false,
  });

  const ratingValues = feedbackForm.watch('orderBy') as unknown as { label: string; value: string };

  const handleRoomSelection = (rate?: Partial<HotelRoomPlanInterface & RoomCardProps> | undefined) => {
    // setOpenRateModal(rateInfo);
    if (!rate) return;
    proceedToPayment(rate);
  };

  const proceedToPayment = (rate: Partial<HotelRoomPlanInterface & RoomCardProps> | undefined) => {
    if (selectPaymentType === PaymentTypes.CASH) {
      // handle cash/card payment
    }
    if (selectPaymentType === PaymentTypes.HALF) {
      // handle cash + points payment
    }
    if (selectPaymentType === PaymentTypes.POINTS) {
      // handle points payment
    }
    bookingsDispatch({
      type: 'setCurrentBooking',
      booking: {
        ...rate,
        inDate: userSearchParams?.inDate,
        outDate: userSearchParams?.outDate,
        adult: Number(userSearchParams?.adults),
        children: Number(userSearchParams?.children),
      },
    });
    return navigate(`/booking/payment?rateKey=${rate?.sourceRateKey}`);
  };

  const getHotelFeedback = async (order?: { label: string; value: string }) => {
    const feedbackResponse = await getFeedbackByHotelId(hotelDetails?.rateResults?.sourceHotelCode, order);
    setHotelFeedback(feedbackResponse.data);
  };

  const getHotelMainImage = () => {
    if (!hotelImages) return undefined;
    if (
      hotelImages &&
      hotelImages[HotelImageCategories.EXTERIOR] &&
      hotelImages[HotelImageCategories.EXTERIOR].length > 0
    ) {
      return hotelImages[HotelImageCategories.EXTERIOR][0]?.ImageItems?.Image[0]?.Url;
    }
    if (
      hotelImages &&
      hotelImages[HotelImageCategories.NO_CATEGORY] &&
      hotelImages[HotelImageCategories.NO_CATEGORY].length > 0
    ) {
      return hotelImages[HotelImageCategories.NO_CATEGORY][0]?.ImageItems?.Image[0]?.Url;
    }
    return undefined;
  };

  const getHotelGuestRoomImage = () => {
    if (!hotelImages) return undefined;
    if (
      hotelImages &&
      hotelImages[HotelImageCategories.GUEST_ROOM] &&
      hotelImages[HotelImageCategories.GUEST_ROOM].length > 0
    ) {
      return hotelImages[HotelImageCategories.GUEST_ROOM];
    }
    if (
      hotelImages &&
      hotelImages[HotelImageCategories.GUEST_AMENITY] &&
      hotelImages[HotelImageCategories.GUEST_AMENITY].length > 0
    ) {
      return hotelImages[HotelImageCategories.GUEST_AMENITY];
    }
    return undefined;
  };

  const getRoomCardImage = (roomCode?: string) => {
    if (!roomCode || !hotelImages) return undefined;
    if (hotelImages[HotelImageCategories.GUEST_ROOM] && hotelImages[HotelImageCategories.GUEST_ROOM].length > 0) {
      const roomImage = hotelImages[HotelImageCategories.GUEST_ROOM].find((categoryImage) =>
        categoryImage?.AdditionalInfo?.Info?.find(
          (info) => info.Type === 'ROOM_TYPE_CODE' && info?.Description?.Text?.some((x) => x.value === roomCode),
        ),
      );
      const randomRoomImage = hotelImages[HotelImageCategories.GUEST_ROOM][0];
      return roomImage?.ImageItems?.Image[0]?.Url ?? randomRoomImage?.ImageItems?.Image[0]?.Url;
    }
    return undefined;
  };

  useEffect(() => {
    const groupByCode = groupBy(hotelDetails?.media, 'Category.CategoryCode') as HotelImagesInterface;
    if (groupByCode) {
      const flatImages = Object.values(groupByCode).flat();
      const groupByCategory = groupBy(
        flatImages,
        (image) => image?.Category?.Description?.Text[0]?.value ?? 'No category',
      );

      setHotelImages(groupByCategory);
    }
    if (hotelDetails?.rateResults?.sourceHotelCode) {
      getHotelFeedback();
    }

    if (hotelDetails?.rateResults?.roomsAvail && !hotelNewRates?.rateResults) {
      setHotelRates(hotelDetails?.rateResults?.roomsAvail);
    }
    if (hotelNewRates) {
      setHotelRates(hotelNewRates?.rateResults?.roomsAvail);

      setSearchParams({ id: hotelNewRates?.rateResults?.roomsAvail?.[0]?.ratesAvail?.[0]?.sourceRateKey ?? '' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hotelDetails, hotelNewRates]);
  console.log(ratingValues);
  useEffect(() => {
    if (ratingValues) {
      getHotelFeedback(ratingValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ratingValues]);

  if (hotelId && hotelId === 'null' && !parsedRateKey) {
    navigate('/');
  }

  return {
    feedbackForm,
    hotelDetails,
    isFetching,
    isPending,
    openRateModal,
    handleRoomSelection,
    latitude: Number(searchParams.get('searchLatitude') ?? 0),
    longitude: Number(searchParams.get('searchLongitude') ?? 0),
    getPriceFormat,
    userSearchParams,
    setPaymentType,
    selectPaymentType,
    proceedToPayment,
    showMoreReviews,
    setShowMoreReviews,
    hotelImages,
    hotelFeedback,
    showAllPhotos,
    setShowAllPhotos,
    hotelMainImage: getHotelMainImage(),
    hotelGuestRoomImage: getHotelGuestRoomImage(),
    getRoomCardImage,
    hotelRates,
    mutate,
  };
};
